import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: "https://de78b2062ff04f7e840e768c1c85b8a5@o1277875.ingest.sentry.io/6475678",
    sampleRate: 1.0, // Adjust this value in production
    environment: process.env.NODE_ENV,
    debug: true,
    tracesSampleRate: 0.2,
    browserTracingOptions: {
      // disable creating spans for XHR requests
      tracingOrigins: [process.env.SITE_URL, /^\//],
    },
});
